@media (max-width: 995px) {
    .main-wala-card{
        width:75vw !important;
        text-align:center !important;
    }
    .main-wala-avatar{
        text-align:center !important;
        margin:auto !important;
        margin-top: 20px !important;
    }
}

@media (min-width: 1000px){
    .main-wala-card{
        width:50vw !important;
        /* text-align:center !important; */
    }
    .main-wala-avatar{
        text-align:center !important;
        margin:auto !important;
        margin-top: 20px !important;
    }
    
}